import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Button, Heading, Text } from '@chakra-ui/react'
import { Link } from 'gatsby'

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <Heading marginBottom="1rem" size="lg">
        Willkommen in der offiziellen Club App!
      </Heading>
      <Text marginBottom="2.5rem">
        Hier kannst du bereits die Bestenlisten der Trainingstage einsehen.
        Weitere Features sind in Planung...
      </Text>
      <Link to="/leaderboard">
        <Button>Zum Leaderboard</Button>
      </Link>
    </Layout>
  )
}

export default IndexPage
